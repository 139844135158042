<template>
  <div class="container h-full p-4">
    <div class="flex flex-col mb-4 h-full">
      <ion-content class="ion-padding" ref="scrollContainer">
        <ion-refresher v-if="swiperActiveIndex === 2" slot="fixed" @ionRefresh="handleRefresh($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <swiper ref="swiper" :allowTouchMove="false" :breakpoints="sliderBreakpoints" :loop="false" @swiper="onSwiper">
          <swiper-slide class="flex-wrap justify-content-left">
            <ion-card class="audio-list w-full ml-0" @click="loadAudios('generic')">
              <ion-item>
                <ion-icon slot="start" :icon="micOutline" color="black" />
                <ion-card-title>Generisch</ion-card-title>
                <ion-icon slot="end" :icon="arrowForwardOutline" />
              </ion-item>
            </ion-card>
            <ion-card class="audio-list w-full ml-0" @click="loadAudios('home')">
              <ion-item>
                <ion-icon slot="start" :icon="homeOutline" color="black" />
                <ion-card-title>Heimspiele</ion-card-title>
                <ion-icon slot="end" :icon="arrowForwardOutline" />
              </ion-item>
            </ion-card>
            <ion-card class="audio-list w-full ml-0" @click="loadAudios('away')">
              <ion-item>
                <ion-icon slot="start" :icon="busOutline" color="black" />
                <ion-card-title>Auswärtsspiele</ion-card-title>
                <ion-icon slot="end" :icon="arrowForwardOutline" />
              </ion-item>
            </ion-card>
          </swiper-slide>
          <swiper-slide class="flex-wrap justify-content-left">
            <ion-card class="back-button" @click="moveSlide(0)">
              <ion-item>
                <ion-icon slot="start" :icon="arrowBackCircleOutline" color="black" />
                <ion-card-title>Zurück</ion-card-title>
              </ion-item>
            </ion-card>
            <ion-card
              v-for="element in elementKeys"
              :key="element.key"
              class="audio-list foo"
              @click="filterAudios(element.key)"
            >
              <ion-item>
                <ion-card-title>{{ element.translated }}</ion-card-title>
                <ion-icon slot="end" :icon="arrowForwardOutline" />
              </ion-item>
            </ion-card>
          </swiper-slide>
          <swiper-slide class="flex-wrap">
            <ion-card class="back-button" @click="moveSlide(1)">
              <ion-item>
                <ion-icon slot="start" :icon="arrowBackCircleOutline" class="upload-icon" color="black" />
                <ion-card-title>Zurück</ion-card-title>
              </ion-item>
            </ion-card>
            <label
              v-if="!isNative"
              ref="drag"
              :class="{ over: isOver }"
              class="upload-area cursor-pointer"
              for="upload-file"
              @drop.prevent="dropFile($event)"
              @dragover.prevent
            >
              <ion-icon :icon="cloudUploadOutline" class="cloud-icon" />
              <input
                id="upload-file"
                ref="cloudfile"
                accept="audio/*"
                class="hidden"
                type="file"
                @change="requestFileUpload($event, 'audio')"
              />
            </label>
            <auto-audio-list
              :autoAudios="shownAutoAudios"
              :editActive="editActive"
              :loading="loading"
              :audioActive="audioActive"
              :loadingFailed="loadingFailed"
              :mediaAutoAudioProtectedPermission="mediaAutoAudioProtectedPermission"
            />
          </swiper-slide>
        </swiper>
        <Transition name="fade">
          <audio-recorder v-if="showButtons" @postAudio="uploadAudioBlob" template="fixed" />
        </Transition>
        <Transition name="fade">
          <div v-if="showButtons" class="edit-button bg-primary cursor-pointer" @click="toggleSlides">
            <ion-icon v-if="!editActive" :icon="create" class="edit-button__icon" />
            <ion-icon v-else :icon="checkmarkSharp" class="edit-button__icon" />
          </div>
        </Transition>
        <Transition name="fade">
          <fixed-add-button v-if="showButtons" accept="audio/*" @emitFile="requestFileUpload($event, 'audio')" />
        </Transition>
      </ion-content>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  arrowBackCircleOutline,
  arrowForwardOutline,
  busOutline,
  checkmarkSharp,
  cloudUploadOutline,
  create,
  footballOutline,
  homeOutline,
  micOutline,
  volumeHighOutline,
} from 'ionicons/icons';
import { alertController, IonCard, IonCardTitle, IonContent, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import AutoAudioList from '@/components/autoAudio/AutoAudioList.vue';
import { useAppStore } from '@/store/app';
import { useAuthStore } from '@/store/auth';
import { useMatchStore } from '@/store/match';
import axios from 'axios';
import fixedAddButton from '@/components/uiComponents/FixedAddButton.vue';
import AudioRecorder from '@/components/audioRecorder/components/AudioRecorder.vue';
import UploadFilesService from '@/services/uploadFiles';
import { emitter } from '@/bus';

export default defineComponent({
  name: 'AutoAudio',
  data() {
    return {
      elementKeys: [
        {
          key: 'MatchWelcome',
          translated: 'Willkommen',
        },
        {
          key: 'MatchKickoff',
          translated: 'Anstoß',
        },
        {
          key: 'MatchHalftime',
          translated: 'Halbzeit',
        },
        {
          key: 'MatchHalftimeKickoff',
          translated: 'Anstoß 2. Halbzeit',
        },
        {
          key: 'MatchGoal-Win',
          translated: 'Tor',
        },
        {
          key: 'MatchGoal-Lose',
          translated: 'Gegentor',
        },
        /*{
          key: 'MatchPenaltyKickAwarded',
          translated: 'MatchPenaltyKickAwarded',
        }, not supported yet*/
        {
          key: 'MatchSubstitution',
          translated: 'Spielerwechsel',
        },
        {
          key: 'MatchEnd',
          translated: 'Abpfiff',
        },
        {
          key: 'GoalAnthem',
          translated: 'Torhymnen',
        },
        {
          key: 'Chant',
          translated: 'Gesänge',
        },
      ],
      sliderBreakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 25,
          centeredSlides: true,
        },
      },
      isNative: useAppStore().isNative,
      isOver: false,
      autoAudios: [],
      shownAutoAudios: [],
      swiper: null as any,
      showButtons: false,
      editActive: false,
      scope: '',
      selectedElementKey: '',
      loading: false,
      loadingFailed: false,
      mediaAutoAudioProtectedPermission: false,
      audioActive: false,
      arrowForwardOutline,
      homeOutline,
      busOutline,
      footballOutline,
      volumeHighOutline,
      micOutline,
      arrowBackCircleOutline,
      create,
      checkmarkSharp,
      cloudUploadOutline,
    };
  },
  computed: {
    swiperActiveIndex() {
      if (this.swiper) {
        return this.swiper.activeIndex;
      } else {
        return false;
      }
    },
    optionsTitle() {
      let scopeTranslated = '';

      if (this.scope === 'generic') {
        scopeTranslated = 'Generisch';
      } else if (this.scope === 'home') {
        scopeTranslated = 'Heimspiele';
      } else if (this.scope === 'away') {
        scopeTranslated = 'Auswärtsspiele';
      }

      if (this.scope && this.selectedElementKey) {
        const selectedElement = this.elementKeys.find((element) => element.key === this.selectedElementKey);
        if (selectedElement) {
          return scopeTranslated + ': ' + selectedElement.translated;
        }
      } else if (this.scope) {
        return scopeTranslated;
      }
      return 'AUTO-AUDIO';
    },
  },
  watch: {
    swiperActiveIndex(val) {
      if (val === 0) {
        this.audioActive = false;
        this.selectedElementKey = '';
        this.scope = '';
      } else if (val === 1) {
        this.audioActive = false;
        this.selectedElementKey = '';
      } else if (val === 2) {
        this.audioActive = true;
      }
      this.showButtons = val === 2;
      this.editActive = false;
    },
  },
  created() {
    emitter.on('refreshPage', () => {
      this.loadAudios(this.scope, false, true);
    });
  },
  async mounted() {
    const authStore = useAuthStore();
    this.mediaAutoAudioProtectedPermission = await authStore.userHasPermissionForEntity(
      'media_autoaudio_protected',
      authStore.userSettings.activeClub.uid,
    );
    (this.$refs['drag'] as any).addEventListener('dragover', () => {
      this.isOver = true;
    });
    (this.$refs['drag'] as any).addEventListener('dragleave', () => {
      this.isOver = false;
    });
  },
  beforeUnmount() {
    emitter.all.delete('refreshPage');
  },
  components: {
    AutoAudioList,
    Swiper,
    SwiperSlide,
    fixedAddButton,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonCard,
    IonCardTitle,
    AudioRecorder,
  },
  methods: {
    uploadAudioBlob() {
      this.requestFileUpload(useMatchStore().recordedBlob, 'audioBlob');
    },
    moveSlide(i: number) {
      this.swiper?.slideTo(i);
    },
    onSwiper(swiper: any) {
      this.swiper = swiper;
    },
    loadAudios(scope: string, swipe = true, filter = false) {
      const authStore = useAuthStore();
      if (authStore.userSettings.activeClub && authStore.userSettings.activeClub.uid) {
        if (swipe) {
          this.swiper?.slideTo(1);
        }
        this.loadingFailed = false;
        this.loading = true;
        this.scope = scope;
        const requestURLGet =
          'https://cloud-' +
          useAppStore().environment +
          '.leagues.football/api/autoaudio/list?entityId=' +
          authStore.userSettings.activeClub.uid +
          '&scope=' +
          scope;
        axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.defaults.headers.get['Authorization'] = 'Bearer ' + authStore.accessToken;
        axios
          .request<any>({
            method: 'get',
            url: requestURLGet,
          })
          .then(async (response) => {
            this.autoAudios = response.data;
            this.loading = false;
            if (filter) {
              await this.filterAudios(this.selectedElementKey);
            }
          })
          .catch(() => {
            this.loadingFailed = true;
            this.loading = false;
          });
      }
    },
    async filterAudios(elementKey: string) {
      (this.$refs['scrollContainer'] as any).$el.scrollToPoint(0, 0, 0);
      this.swiper?.slideTo(2);
      this.selectedElementKey = elementKey;

      if (this.loading) {
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (!this.loading) {
              clearInterval(interval);
              resolve(null);
            }
          }, 100);
        });
      }

      this.shownAutoAudios = this.autoAudios.filter((obj: any) => {
        return obj.group === elementKey;
      });
    },
    toggleSlides() {
      this.editActive = !this.editActive;
    },
    async handleRefresh(event: any) {
      await this.loadAudios(this.scope, false, true);
      setTimeout(async () => {
        event.target.complete();
      }, 500);
    },
    async requestFileUpload(e: any, uploadType: string) {
      const inputs = [] as any;
      if (this.mediaAutoAudioProtectedPermission) {
        inputs.push({
          name: 'Checkbox 1',
          type: 'checkbox',
          label: 'Geschützte Datei',
          value: 'protectedFile',
        });
      }
      const alert = await alertController.create({
        header: 'Auto-Audio hochladen',
        inputs: inputs,
        cssClass: 'custom-alert',
        buttons: [
          {
            text: 'Ja',
            role: 'confirm',
            handler: (data) => {
              const protectedFile = data.indexOf('protectedFile') > -1;
              this.uploadFile(e, protectedFile, uploadType);
            },
          },
          {
            text: 'abbrechen',
            role: 'cancel',
          },
        ],
      });

      await alert.present();
    },
    uploadFile(e: any, protectedAudio: boolean, uploadType: string) {
      let uploadUrl = 'https://cloud-' + useAppStore().environment + '.leagues.football/api/autoaudio/';
      if (protectedAudio) {
        if (this.selectedElementKey === 'GoalAnthem' || this.selectedElementKey === 'Chant') {
          uploadUrl += 'uploadProtectedBackground';
        } else {
          uploadUrl += 'uploadProtectedElement';
        }
      } else {
        if (this.selectedElementKey === 'GoalAnthem' || this.selectedElementKey === 'Chant') {
          uploadUrl += 'uploadBackground';
        } else {
          uploadUrl += 'uploadElement';
        }
      }
      uploadUrl += '?entityId=' + useAuthStore().userSettings.activeClub.uid;
      if (this.selectedElementKey === 'GoalAnthem' || this.selectedElementKey === 'Chant') {
        uploadUrl += '&backgroundKey=' + this.selectedElementKey;
      } else {
        uploadUrl += '&elementKey=' + this.selectedElementKey;
      }
      uploadUrl += '&scope=' + this.scope;

      if (uploadType === 'audio') {
        UploadFilesService.uploadFile(e, uploadUrl, uploadType);
      } else if (uploadType === 'audioBlob') {
        UploadFilesService.prepareUploadAudioBlob(uploadUrl);
      }
    },
    dropFile(e: any) {
      this.isOver = false;
      const droppedFiles = e.dataTransfer.files[0];
      if (e.dataTransfer.files.length === 1 && droppedFiles.type.includes('audio')) {
        this.requestFileUpload(droppedFiles, 'audio');
      } else if (e.dataTransfer.files.length > 1) {
        useAppStore().postFailed(
          '',
          'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
          'Du kannst nur eine Datei am Stück posten',
        );
      } else {
        useAppStore().postFailed(
          '',
          'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
          'Du kannst hier nur <b>Audios</b> hochladen',
        );
      }
    },
  },
});
</script>

<style lang="scss" scoped>
ion-content {
  --ion-background-color: black;
}

.audio-list {
  cursor: pointer;

  ion-icon {
    height: 65px;
  }

  ion-card-title {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.back-button {
  cursor: pointer;

  ion-icon {
    height: 25px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.edit-button {
  position: fixed;
  bottom: 85px;
  right: 15px;
  width: 60px;
  border-radius: 50%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &__icon {
    fill: black;
    border-radius: 5px;
    padding: 3px;
    width: 30px;
    height: 30px;
  }
}

.upload-area {
  height: 180px;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-color: var(--ion-tab-bar-background, #1f1f1f) !important;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(--ion-color-primary-rgb), 0.5);
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.over:before {
    opacity: 1;
  }

  .cloud-icon {
    color: var(--ion-tab-bar-background, #1f1f1f);
    height: 100px;
    width: 100px;
  }
}

.justify-content-left {
  justify-content: left !important;
}
</style>
