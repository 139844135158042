import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import type { CreatorFile } from '@/services/filePicker/CreatorFile';

export type ApiResponse<T> =
  | {
      data: T;
      status: number | null;
      error: null;
    }
  | { data: null; status: number | null; error: AxiosError | string };

type ApiDomain = 'profiles' | 'cloud' | 'timelines' | 'hubs' | 'supersub';

const getHeadersContentType = (contentType?: string, filename?: string) => {
  const authStore = useAuthStore();
  return {
    Authorization: `Bearer ${authStore.accessToken}`,
    ...(contentType ? { 'Content-Type': contentType } : {}),
    ...(filename ? { 'Content-Disposition': `attachment; name="${filename}"; filename="${filename}"` } : {}),
  } as const;
};

const getHeaders = (contentType?: string) => {
  const authStore = useAuthStore();
  return {
    Authorization: `Bearer ${authStore.accessToken}`,
    ...(contentType ? { 'Content-Type': contentType } : {}),
  } as const;
};

export default {
  methods: {
    getHeadersContentType,
    getHeaders,
    setHeaders: function (contentType = '', type = '', filename = ''): void {
      const authStore = useAuthStore();
      axios.defaults.headers.common['Authorization'] = `Bearer ${authStore.accessToken}`;
      if (contentType && type) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        axios.defaults.headers[type]['Content-Type'] = contentType;
        if (filename) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          axios.defaults.headers[type]['Content-Disposition'] =
            `attachment; name="${filename}"; filename="${filename}"`;
        }
      }
    },
    getApiUrl: function (api: 'profiles' | 'cloud' | 'timelines' | 'hubs' | 'supersub' | 'streaming', url: string) {
      const appStore = useAppStore();
      let baseUrl: string;
      if (api === 'profiles') {
        baseUrl = `https://profiles-${appStore.environment}.leagues.network`;
      } else if (api === 'cloud') {
        baseUrl = `https://cloud-${appStore.environment}.leagues.football`;
      } else if (api === 'timelines') {
        baseUrl = `https://timelines-${appStore.environment}.leagues.network`;
      } else if (api === 'hubs') {
        baseUrl = `wss://supersub-${appStore.environment}.leagues.network`;
      } else if (api === 'supersub') {
        baseUrl = `https://supersub-${appStore.environment}.leagues.network`;
      } else if (api === 'streaming') {
        baseUrl = 'https://api-dev.ott.leagues.network';
      } else {
        throw new Error('Invalid API');
      }

      let finalUrl: string;

      // Prüfen, ob die URL tatsächlich eine vollständige URL ist oder nur ein Pfad
      if (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('wss://')) {
        // Erstellt ein URL-Objekt aus dem übergebenen String
        const urlObj = new URL(url);
        // Holt nur den Pfad und die Abfrage der URL
        finalUrl = urlObj.pathname + urlObj.search;
      } else {
        // Wenn es nur ein Pfad ist, verwenden wir ihn direkt
        finalUrl = url;
      }

      // Stellt sicher, dass 'finalUrl' nicht mit einem Slash beginnt, um doppelte Schrägstriche zu vermeiden
      finalUrl = finalUrl.startsWith('/') ? finalUrl.slice(1) : finalUrl;

      return `${baseUrl}/${finalUrl}`;
    },

    async patch<T = any>(
      url: string,
      api: ApiDomain,
      data: any,
      { contentType = 'application/json', abortSignal }: { contentType?: string; abortSignal?: AbortSignal } = {},
    ): Promise<ApiResponse<T>> {
      this.setHeaders(contentType, 'patch');
      const apiUrl = this.getApiUrl(api, url);

      try {
        const response = await axios.patch(apiUrl, data, {
          headers: { 'Content-Type': contentType },
          signal: abortSignal,
        });
        return { data: response.data, status: response.status, error: null };
      } catch (error) {
        console.error('Fehler bei der PATCH-Anfrage ' + url + ': ', error);
        return { data: null, status: 400, error: error as AxiosError };
      }
    },

    async get<T = any>(
      url: string,
      api: ApiDomain,
      {
        contentType = 'application/x-www-form-urlencoded',
        abortSignal,
      }: { contentType?: string; abortSignal?: AbortSignal } = {},
    ): Promise<ApiResponse<T>> {
      this.setHeaders(contentType, 'get');
      const apiUrl = this.getApiUrl(api, url);

      try {
        const response = await axios.get(apiUrl, { signal: abortSignal });
        return { data: response.data, status: response.status, error: null };
      } catch (error) {
        console.error('Fehler bei der GET-Anfrage ' + url + ': ', error);
        return { data: null, status: 400, error: error as AxiosError };
      }
    },

    async post<T = any>(
      url: string,
      api: ApiDomain,
      data: object,
      {
        contentType = 'application/json',
        onProgress,
        abortSignal,
      }: {
        contentType?: string;
        onProgress?: AxiosRequestConfig['onUploadProgress'];
        abortSignal?: AxiosRequestConfig['signal'];
      } = {},
    ): Promise<ApiResponse<T>> {
      this.setHeaders(contentType, 'post');
      const apiUrl = this.getApiUrl(api, url);

      try {
        const response = await axios.post(apiUrl, data, {
          onUploadProgress: onProgress,
          signal: abortSignal,
        });
        return { data: response.data, status: response.status, error: null };
      } catch (error) {
        console.error('Fehler bei der POST-Anfrage: ', error);

        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<{ title: string; detail: string }>;

          // Prüfen, ob der Fehler ein Response-Objekt enthält
          if (axiosError.response) {
            const serverResponse = axiosError.response.data;
            if (serverResponse) {
              console.error('Konflikt erkannt: ', serverResponse);
              return {
                data: null,
                status: axiosError.response.status,
                error: serverResponse.title + ' - ' + serverResponse.detail,
              };
            }
          }
        }

        return {
          data: null,
          status: axios.isAxiosError(error) ? error.status ?? 500 : 400,
          error: 'Unbekannter Fehler',
        };
      }
    },

    async put<T = any>(
      url: string,
      api: ApiDomain,
      data: object,
      contentType = 'application/json',
    ): Promise<ApiResponse<T>> {
      this.setHeaders(contentType, 'put');
      const apiUrl = this.getApiUrl(api, url);
      try {
        const response = await axios.put(apiUrl, data);
        return { data: response.data, status: response.status, error: null };
      } catch (error) {
        console.error('Fehler bei der PUT-Anfrage: ', error);
        return { data: null, status: 400, error: error as AxiosError };
      }
    },

    async uploadFile<T = any>(url: string, api: ApiDomain, file: File, contentType = ''): Promise<ApiResponse<T>> {
      const apiUrl = this.getApiUrl(api, url);
      const headers = { 'Content-Type': contentType || file.type };
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: file,
      });
      const data = await response.json();
      return { data: data as T, status: response.status, error: null };
    },

    async postBlob<T = any>(url: string, api: ApiDomain, blob: Blob, contentType = ''): Promise<ApiResponse<T>> {
      const apiUrl = this.getApiUrl(api, url);
      const headers = { 'Content-Type': contentType, 'Content-Length': blob.size.toString() };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers,
        body: blob,
      });
      const data = await response.json();
      return { data: data as T, status: response.status, error: null };
    },

    async postCreatorFile<T = any>(
      url: string,
      api: ApiDomain,
      file: CreatorFile,
      {
        onProgress,
        abortSignal,
      }: { onProgress?: AxiosRequestConfig['onUploadProgress']; abortSignal?: AxiosRequestConfig['signal'] } = {},
    ): Promise<ApiResponse<T>> {
      this.setHeaders(file.mimeType, 'post');
      const apiUrl = this.getApiUrl(api, url);

      const formData = new FormData();
      formData.append('file', file.blob, file.name);

      try {
        const response = await axios.post(apiUrl, formData, {
          onUploadProgress: onProgress,
          signal: abortSignal,
        });
        return { data: response.data, status: response.status, error: null };
      } catch (error) {
        console.error('Fehler bei der POST-Anfrage: ', error);
        return { data: null, status: 400, error: error as AxiosError };
      }
    },

    async postFile<T = any>(
      url: string,
      api: ApiDomain,
      file: File,
      contentType = '',
      {
        onProgress,
        abortSignal,
      }: { onProgress?: AxiosRequestConfig['onUploadProgress']; abortSignal?: AxiosRequestConfig['signal'] } = {},
    ): Promise<ApiResponse<T>> {
      this.setHeaders(contentType || file.type, 'post');
      const apiUrl = this.getApiUrl(api, url);

      // 'Content-Type': 'multipart/form-data' wird automatisch gesetzt, wenn wir axios mit FormData verwenden.
      const formData = new FormData();
      formData.append('file', file, file.name);

      try {
        const response = await axios.post(apiUrl, formData, {
          onUploadProgress: onProgress,
          signal: abortSignal,
        });
        return { data: response.data, status: response.status, error: null };
      } catch (error) {
        console.error('Fehler bei der POST-Anfrage: ', error);
        return { data: null, status: 400, error: error as AxiosError };
      }
    },

    async postBlobAsFile<T = any>(
      url: string,
      api: ApiDomain,
      blob: Blob,
      filename: string,
      {
        onProgress,
        abortSignal,
        fallbackMimeType,
      }: {
        onProgress?: AxiosRequestConfig['onUploadProgress'];
        abortSignal?: AxiosRequestConfig['signal'];
        fallbackMimeType?: string;
      } = {},
    ): Promise<ApiResponse<T>> {
      // Sanity check
      const fileExt = filename.split('.').pop();
      if (!fileExt) {
        console.error(`Invalid or missing file extension: ${filename}`);
        throw new Error('Unable to determine file extension.');
      }

      this.setHeaders('multipart/form-data', 'post', filename);
      const apiUrl = this.getApiUrl(api, url);
      const newBlob = new Blob([blob], { type: blob.type ?? fallbackMimeType });

      const formData = new FormData();
      formData.append('file', newBlob, filename);

      try {
        const response = await axios.post(apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Optional, da axios dies automatisch setzt
          },
          onUploadProgress: onProgress,
          signal: abortSignal,
        });
        return { data: response.data, status: response.status, error: null };
      } catch (error) {
        console.error('Fehler bei der POST-Anfrage: ', error);
        return { data: null, status: 400, error: error as AxiosError };
      }
    },

    async putFile<T = any>(url: string, api: ApiDomain, file: File, contentType = ''): Promise<ApiResponse<T>> {
      this.setHeaders(contentType, 'put');
      const apiUrl = this.getApiUrl(api, url);

      // 'Content-Type': 'multipart/form-data' wird automatisch gesetzt, wenn wir axios mit FormData verwenden.
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.put(apiUrl, formData);
        return { data: response.data, status: response.status, error: null };
      } catch (error) {
        console.error('Fehler bei der POST-Anfrage: ', error);
        return { data: null, status: 400, error: error as AxiosError };
      }
    },

    async delete<T = any>(
      url: string,
      api: ApiDomain,
      data: object = {},
      contentType = 'application/json',
    ): Promise<ApiResponse<T>> {
      this.setHeaders(contentType, 'delete');
      const apiUrl = this.getApiUrl(api, url);

      try {
        const response = await axios.delete(apiUrl, { data: data });
        return { data: response.data, status: response.status, error: null };
      } catch (error) {
        console.error('Fehler bei der DELETE-Anfrage: ', error);
        return { data: null, status: 400, error: error as AxiosError };
      }
    },
  },
} as const;
