import uploadFilesV2, { MediaElementPayload } from '@/services/uploadFilesV2';
import { v4 as uuidv4 } from 'uuid';
import { usePublishQueueAssets } from '@/store/uploadQueueAssets';
import { RetriesExceededError, Urgency, usePreprocessingQueue, useTaskQueue } from '@leagues-football/upload-queue';
import apiRequest, { type ApiResponse } from '@/services/apiRequest';
import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import { FileKind, PreviewURL } from '@/services/filePicker/models';
import { CloudFile } from '@/views/cloud-page/CloudFile';
import { getFileKindFromMimeType } from '@/services/filePicker/mime-types';
import { useTimelineStore } from '@/store/timeline';
import { type CreatorFile, creatorFileFromNativeFilePath } from '@/services/filePicker/CreatorFile';
import helper from '@/services/helper';

/**
 * The publish service exposes functions using the publish and preprocessing queues.
 */
const publish = {
  async createCardWithMedia({
    file,
    headline,
    thumbnail,
    silentPost = false,
  }: {
    file: CreatorFile;
    headline: string;
    thumbnail?: PreviewURL;
    silentPost?: boolean;
  }) {
    // Start enqueueing the file compression task immediately, so that when the publish task becomes active, the compression task may already be completed.
    const compressionTaskID = uploadFilesV2.requiresCompression(file)
      ? await uploadFilesV2.enqueueCompressionTask(file)
      : null;

    const cardID = uuidv4();
    const publishTaskID = `${cardID}-${uuidv4()}`;

    usePublishQueueAssets().uploadTaskThumbnails.set(publishTaskID, thumbnail ?? null);

    useTaskQueue().enqueueTask(
      publishTaskID,
      file.name,
      Urgency.Medium,
      async ({ onProgressStep, onAxiosProgressStep, abortSignal }) => {
        if (compressionTaskID) {
          const { resultFilePath } = await usePreprocessingQueue().wait(compressionTaskID, {
            onProgress: (progress: number) => onProgressStep('compressing', 'Komprimieren...', 5 / 10, progress),
            abortSignal,
          });

          file = await creatorFileFromNativeFilePath(
            resultFilePath,
            'video/mp4', // Every compressed video will be an mp4 file
            file,
          );
        }

        const response = await uploadFilesV2.uploadCardAsset(file, cardID, {
          onProgress: onAxiosProgressStep('upload', 'Hochladen...', compressionTaskID ? 2 / 10 : 7 / 10, {
            waitForServerFraction: 0.4,
            waitForServerDisplayText: 'Verarbeiten...',
          }),
          abortSignal,
        });

        if (!ensureSuccess(response)) {
          helper.methods.microInteraction(
            'Beim hochladen der Datei ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
          );
          return;
        }

        const card = {
          elements: [buildElement(file, headline, response.data)],
          id: cardID,
          track: 'Main',
          class: 'Match',
          event: 'Match',
          isVisual: true,
          orientation: 'PortraitMandatory',
          tags: [],
        } as const;

        const addCardResponse = await apiRequest.methods.post(
          `api/timelines/addCard?silent=${silentPost}`,
          'cloud',
          card,
          {
            onProgress: onAxiosProgressStep('add-card', 'Karte hinzufügen...', 2 / 10, {
              waitForServerFraction: 0.9,
              waitForServerDisplayText: 'Karte hinzufügen...',
            }),
            abortSignal,
          },
        );

        if (!ensureSuccess(addCardResponse)) {
          helper.methods.microInteraction(
            'Beim hinzufügen der Card ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
          );
          return;
        }

        const placeCardResponse = await apiRequest.methods.post(
          `api/timelines/placeCard?cardId=${cardID}&timelineId=${useAuthStore().userSettings?.selectedTimeline?.uid}`,
          'cloud',
          {},
          {
            onProgress: onAxiosProgressStep('place-card-on-timeline', 'Karte auf Timeline platzieren...', 1 / 10),
            abortSignal,
          },
        );

        if (!ensureSuccess(placeCardResponse)) {
          helper.methods.microInteraction(
            'Beim platzieren der Card auf der Timeline ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
          );
          return;
        }

        await useTimelineStore().refetchToday();
      },
      {
        onRetriesExceeded: (failedTask, error) => {
          const bannerObject = {
            success: false,
            message: `Der Upload von ${failedTask.displayName} ist nach ${failedTask.attempts} Versuchen fehlgeschlagen!`,
            error,
          };
          useAppStore().addBanner(bannerObject);
        },
      },
    );
  },

  async createCardWithCloudFile({
    cloudFile,
    headline,
    thumbnail,
    silentPost = false,
  }: {
    cloudFile: CloudFile;
    headline: string;
    thumbnail?: PreviewURL;
    silentPost?: boolean;
  }) {
    const cardID = uuidv4();
    const publishTaskID = `${cardID}-${uuidv4()}`;

    usePublishQueueAssets().uploadTaskThumbnails.set(publishTaskID, thumbnail ?? null);

    useTaskQueue().enqueueTask(
      publishTaskID,
      cloudFile.fileName,
      Urgency.Medium,
      async ({ onAxiosProgressStep, abortSignal }) => {
        const card = {
          elements: [buildElement(cloudFile, headline)],
          id: cardID,
          track: 'Main',
          class: 'Match',
          event: 'Match',
          isVisual: true,
          orientation: 'PortraitMandatory',
          tags: [],
        } as const;

        const addCardResponse = await apiRequest.methods.post(
          `api/timelines/addCard?silent=${silentPost}`,
          'cloud',
          card,
          {
            onProgress: onAxiosProgressStep('add-card', 'Karte hinzufügen...', 5 / 10, {
              waitForServerFraction: 0.3,
              waitForServerDisplayText: 'Karte hinzufügen...',
            }),
            abortSignal,
          },
        );

        if (!ensureSuccess(addCardResponse)) {
          helper.methods.microInteraction(
            'Beim hinzufügen der Card ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
          );
          return;
        }

        const placeCardResponse = await apiRequest.methods.post(
          `api/timelines/placeCard?cardId=${cardID}&timelineId=${useAuthStore().userSettings?.selectedTimeline?.uid}`,
          'cloud',
          {},
          {
            onProgress: onAxiosProgressStep('place-card-on-timeline', 'Karte auf Timeline platzieren...', 5 / 10),
            abortSignal,
          },
        );

        if (!ensureSuccess(placeCardResponse)) {
          helper.methods.microInteraction(
            'Beim Platzieren der Card ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
          );
          return;
        }

        await useTimelineStore().refetchToday();
      },
      {
        onRetriesExceeded: (failedTask, error) => {
          const bannerObject = {
            success: false,
            message: `Das erstellen der Card mit ${failedTask.displayName} ist nach ${failedTask.attempts} Versuchen fehlgeschlagen!`,
            error,
          };
          useAppStore().addBanner(bannerObject);
        },
      },
    );
  },

  async attachMediaToCard({
    file,
    cardID,
    headline,
    thumbnail,
  }: {
    file: CreatorFile;
    cardID: string;
    headline: string;
    thumbnail?: PreviewURL;
  }) {
    // Start enqueueing the file compression task immediately, so that when the publish task becomes active, the compression task may already be completed.
    const compressionTaskID = uploadFilesV2.requiresCompression(file)
      ? await uploadFilesV2.enqueueCompressionTask(file)
      : null;

    const publishTaskID = uuidv4();

    usePublishQueueAssets().uploadTaskThumbnails.set(publishTaskID, thumbnail ?? null);

    useTaskQueue().enqueueTask(
      publishTaskID,
      file.name,
      Urgency.Medium,
      async ({ onProgressStep, onAxiosProgressStep, abortSignal }) => {
        if (compressionTaskID) {
          const { resultFilePath } = await usePreprocessingQueue().wait(compressionTaskID, {
            onProgress: (progress: number) => onProgressStep('compressing', 'Komprimieren...', 6 / 10, progress),
            abortSignal,
          });
          file = await creatorFileFromNativeFilePath(
            resultFilePath,
            'video/mp4', // Every compressed video will be an mp4 file
            file,
          );
        }

        const response = await uploadFilesV2.uploadCardAsset(file, cardID, {
          onProgress: onAxiosProgressStep('upload', 'Hochladen...', compressionTaskID ? 2 / 10 : 8 / 10, {
            waitForServerFraction: 0.4,
            waitForServerDisplayText: 'Verarbeiten...',
          }),
          abortSignal,
        });

        if (!ensureSuccess(response)) {
          helper.methods.microInteraction(
            'Beim hochladen der Datei ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
          );
          return;
        }

        const addCardSegmentResponse = await apiRequest.methods.post(
          `api/timelines/addCardSegment?card=${cardID}&creator=${useAuthStore().userUid}`,
          'cloud',
          {
            id: uuidv4(),
            order: 0,
            elements: [{ ...buildElement(file, headline, response.data as string), preloading: true }],
            backgroundImageUrl: null,
            backgroundVideoUrl: null,
            backgroundAudioUrl: null,
            timelines: [],
          },
          {
            onProgress: onAxiosProgressStep('add-to-card', 'Zu Card hinzufügen...', 2 / 10, {
              waitForServerFraction: 0.5,
              waitForServerDisplayText: 'Zur Card hinzufügen...',
            }),
            abortSignal,
          },
        );

        if (!ensureSuccess(addCardSegmentResponse)) {
          helper.methods.microInteraction(
            'Beim hinzufügen der Medien zur Card ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
          );
          return;
        }

        await useTimelineStore().invalidateCard(cardID);
      },
      {
        onRetriesExceeded: (failedTask, error) => {
          const bannerObject = {
            success: false,
            message: `Der Upload von ${failedTask.displayName} ist nach ${failedTask.attempts} Versuchen fehlgeschlagen!`,
            error,
          };
          useAppStore().addBanner(bannerObject);
        },
      },
    );
  },

  async attachCloudFileToCard({
    cloudFile,
    cardID,
    headline,
    thumbnail,
  }: {
    cloudFile: CloudFile;
    cardID: string;
    headline: string;
    thumbnail?: PreviewURL;
  }) {
    const publishTaskID = uuidv4();

    usePublishQueueAssets().uploadTaskThumbnails.set(publishTaskID, thumbnail ?? null);

    useTaskQueue().enqueueTask(
      publishTaskID,
      cloudFile.fileName,
      Urgency.Medium,
      async ({ onAxiosProgressStep, abortSignal }) => {
        const addCardSegmentResponse = await apiRequest.methods.post(
          `api/timelines/addCardSegment?card=${cardID}&creator=${useAuthStore().userUid}`,
          'cloud',
          {
            id: uuidv4(),
            order: 0,
            elements: [{ ...buildElement(cloudFile, headline), preloading: true }],
            backgroundImageUrl: null,
            backgroundVideoUrl: null,
            backgroundAudioUrl: null,
            timelines: [],
          },
          {
            onProgress: onAxiosProgressStep('add-to-card', 'Zu Card hinzufügen...', 1.0, {
              waitForServerFraction: 0.2,
              waitForServerDisplayText: 'Zur Card hinzufügen...',
            }),
            abortSignal,
          },
        );

        if (!ensureSuccess(addCardSegmentResponse)) {
          helper.methods.microInteraction(
            'Beim hinzufügen der Medien zur Card ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
          );
          return;
        }

        await useTimelineStore().invalidateCard(cardID);
      },
      {
        onRetriesExceeded: (failedTask, error) => {
          const bannerObject = {
            success: false,
            message: `Der Upload von ${failedTask.displayName} ist nach ${failedTask.attempts} Versuchen fehlgeschlagen!`,
            error,
          };
          useAppStore().addBanner(bannerObject);
        },
      },
    );
  },

  async uploadCloudFile({ file, inboxId, thumbnail }: { file: CreatorFile; inboxId: string; thumbnail?: PreviewURL }) {
    // Start enqueueing the file compression task immediately, so that when the publish task becomes active, the compression task may already be completed.
    const compressionTaskID = uploadFilesV2.requiresCompression(file)
      ? await uploadFilesV2.enqueueCompressionTask(file)
      : null;

    const publishTaskID = uuidv4();

    usePublishQueueAssets().uploadTaskThumbnails.set(publishTaskID, thumbnail ?? null);

    try {
      await useTaskQueue().enqueueTaskAndWait(
        publishTaskID,
        file.name,
        Urgency.Low,
        async ({ onProgressStep, onAxiosProgressStep, abortSignal }) => {
          if (compressionTaskID) {
            const { resultFilePath } = await usePreprocessingQueue().wait(compressionTaskID, {
              onProgress: (progress: number) => onProgressStep('compressing', 'Komprimieren...', 5 / 10, progress),
              abortSignal,
            });

            file = await creatorFileFromNativeFilePath(
              resultFilePath,
              'video/mp4', // Every compressed video will be an mp4 file
              file,
            );
          }

          const response = await uploadFilesV2.uploadCloudFile(file, inboxId, {
            onProgress: onAxiosProgressStep('upload', 'Hochladen...', compressionTaskID ? 5 / 10 : 10 / 10, {
              waitForServerFraction: 0.4,
              waitForServerDisplayText: 'Verarbeiten...',
            }),
            abortSignal,
          });

          if (!ensureSuccess(response)) {
            helper.methods.microInteraction(
              'Beim hochladen der Datei ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
            );
            return;
          }
        },
      );
    } catch (exc) {
      const { failedTask, originalError } = exc as RetriesExceededError;
      const bannerObject = {
        success: false,
        message: `Der Upload von ${failedTask.displayName} ist nach ${failedTask.attempts} Versuchen fehlgeschlagen!`,
        error: originalError,
      };
      useAppStore().addBanner(bannerObject);
    }
  },

  async postNews({
    teaser,
    sourceText,
    sourceLogoURL,
    headline,
    body,
    file,
    thumbnailUrl,
    silentPost = false,
  }: {
    sourceText: string;
    sourceLogoURL: string;
    headline: string;
    body: string;
    teaser?: string;
    file?: CreatorFile | CloudFile;
    thumbnailUrl?: PreviewURL;
    silentPost?: boolean;
  }) {
    // Start enqueueing the file compression task immediately, so that when the publish task becomes active, the compression task may already be completed.
    const compressionTaskID =
      file && !isCloudFile(file) && uploadFilesV2.requiresCompression(file)
        ? await uploadFilesV2.enqueueCompressionTask(file)
        : null;

    const cardID = uuidv4();
    const publishTaskID = `${cardID}-${uuidv4()}`;

    usePublishQueueAssets().uploadTaskThumbnails.set(publishTaskID, thumbnailUrl ?? null);

    useTaskQueue().enqueueTask(
      publishTaskID,
      headline,
      Urgency.Medium,
      async ({ onProgressStep, onAxiosProgressStep, abortSignal }) => {
        let keyVisualUrl: string | undefined;
        if (!file) {
          keyVisualUrl = undefined;
        } else if (isCloudFile(file)) {
          keyVisualUrl = file.url;
        } else {
          if (compressionTaskID) {
            const { resultFilePath } = await usePreprocessingQueue().wait(compressionTaskID, {
              onProgress: (progress: number) => onProgressStep('compressing', 'Komprimieren...', 5 / 10, progress),
              abortSignal,
            });

            file = await creatorFileFromNativeFilePath(
              resultFilePath,
              'video/mp4', // Every compressed video will be an mp4 file
              file,
            );
          }

          const response = await uploadFilesV2.uploadCardAsset(file, cardID, {
            onProgress: onAxiosProgressStep('upload', 'Hochladen...', compressionTaskID ? 2 / 10 : 7 / 10, {
              waitForServerFraction: 0.4,
              waitForServerDisplayText: 'Verarbeiten...',
            }),
            abortSignal,
          });

          if (!ensureSuccess(response)) {
            helper.methods.microInteraction(
              'Beim hochladen der Datei ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
            );
            return;
          }

          keyVisualUrl = response.data as string;
        }

        const card = {
          $type: 'Card',
          id: cardID,
          track: 'Main',
          class: 'News',
          event: 'Competition',
          isVisual: true,
          orientation: 'Portrait',
          tags: [],
          elements: [
            {
              $type: 'NewsItem',
              keyvisualUrl: keyVisualUrl,
              headline,
              body,
              teaser: teaser ?? null,
              sourceIdentifier: 'leagues.football',
              sourceLinkUrl: 'https://leagues.football',
              sourceText: sourceText ?? null,
              sourceLogoUrl: sourceLogoURL ?? null,
              preloading: false,
            },
          ],
          navigation: {},
          title: null,
          mediaIsInCache: true,
        } as const;

        const addCardResponse = await apiRequest.methods.post(
          `api/timelines/addCard?silent=${silentPost}`,
          'cloud',
          card,
          {
            onProgress: onAxiosProgressStep('add-card', 'Karte hinzufügen...', 2 / 10, {
              waitForServerFraction: 0.9,
              waitForServerDisplayText: 'Karte hinzufügen...',
            }),
            abortSignal,
          },
        );

        if (!ensureSuccess(addCardResponse)) {
          helper.methods.microInteraction(
            'Beim hinzufügen der Card ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
          );
          return;
        }

        const placeCardResponse = await apiRequest.methods.post(
          `api/timelines/placeCard?cardId=${cardID}&timelineId=${useAuthStore().userSettings?.selectedTimeline?.uid}`,
          'cloud',
          {},
          {
            onProgress: onAxiosProgressStep('place-card-on-timeline', 'Karte auf Timeline platzieren...', 1 / 10),
            abortSignal,
          },
        );

        if (!ensureSuccess(placeCardResponse)) {
          helper.methods.microInteraction(
            'Beim platzieren der Card auf der Timeline ist etwas schief gelaufen. Bitte schließe und starte die App neu, dann probiere es noch einmal!',
          );
          return;
        }

        await useTimelineStore().refetchToday();
      },
      {
        onRetriesExceeded: (failedTask, error) => {
          const bannerObject = {
            success: false,
            message: `Der Upload von ${failedTask.displayName} ist nach ${failedTask.attempts} Versuchen fehlgeschlagen!`,
            error,
          };
          useAppStore().addBanner(bannerObject);
        },
      },
    );
  },
};

function buildElement(file: CreatorFile, title: string, uploadedUrl: string): MediaElementPayload;
function buildElement(cloudFile: CloudFile, title: string): MediaElementPayload;
function buildElement(file: CreatorFile | CloudFile, title: string, uploadedUrl?: string): MediaElementPayload {
  const headline = title && title.trim() !== '' ? title : undefined;

  const url = isCloudFile(file) ? file.url : (uploadedUrl as string);
  const fileKind = isCloudFile(file) ? getFileKindFromMimeType(file.contentType) : file.kind;

  switch (fileKind) {
    case FileKind.Video:
      return { $type: 'Video', videoUrl: url, headline };
    case FileKind.Audio:
      return { $type: 'Audio', audioUrl: url, headline };
    case FileKind.Image:
      return { $type: 'Image', imageUrl: url, headline };
    default:
      // The FilePicker component already ensures we get a valid file type, so this shouldn't happen.
      throw Error(`Unknown file kind: ${fileKind}`);
  }
}

export function isCloudFile(file: CreatorFile | CloudFile): file is CloudFile {
  return 'url' in file;
}

function ensureSuccess(apiResponse: ApiResponse<any>) {
  if (!apiResponse.error || !apiResponse.status || !!apiResponse.data) {
    return true;
  }

  if (apiResponse.status >= 200 && apiResponse.status < 300) {
    return true;
  }

  if (apiResponse.status >= 400 && apiResponse.status < 500) {
    // Bad requests should not trigger a retry
    return false;
  }

  // Throw to trigger a retry
  throw apiResponse.error;
}

export default publish;
