//eslint-disable-next-line @typescript-eslint/ban-ts-comment -- This file is deprecated
//@ts-nocheck
import axios from 'axios';
import { useAppStore } from '@/store/app';
import { useAuthStore } from '@/store/auth';
import { useMatchStore } from '@/store/match';
import { emitter } from '@/bus';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { VideoEditor } from '@awesome-cordova-plugins/video-editor';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@awesome-cordova-plugins/file-transfer';

let controller = new AbortController();
let fileTransfer: ReturnType<typeof FileTransfer.create>;
let uploadId = 0;
export default {
  transcodeVideoOptions: function (filePath: string, fileName: string, uploadId: number) {
    return {
      fileUri: filePath ?? '',
      outputFileName: fileName + '--compressed',
      outputFileType: VideoEditor.OutputFileType.MPEG4, // android is always mp4
      optimizeForNetworkUse: VideoEditor.OptimizeForNetworkUse.YES, // ios only
      saveToLibrary: false,

      maintainAspectRatio: true, // optional (ios only), defaults to true
      width: 540, // optional, see note below on width and height
      height: 960, // optional, see notes below on width and height
      fps: 30, // optional (android only), defaults to 30
      videoBitrate: 1920000, // optional, bitrate in bits, defaults to 9 megabit (9000000)

      audioChannels: 2, // optional, number of audio channels, defaults: iOS - 2, Android - as is
      audioSampleRate: 44100, // optional (ios only), sample rate for the audio, defaults to 44100
      audioBitrate: 96000, // optional, audio bitrate for the video in bits,  defaults: iOS - 128000 (128 kilobits), Android - as is or 128000
      progress: (progress: number) => {
        useAppStore().queue.find((x: any) => x.uploadId === uploadId).uploadProgress = progress / 2;
      },
    };
  },
  cancelUploadDesktop: function () {
    controller.abort();
    controller = new AbortController();
  },
  cancelUploadMobile: function () {
    fileTransfer.abort();
    fileTransfer = FileTransfer.create();
    useAppStore().queue.shift();
  },
  uploadFileAndPostCard: async function (uploadObject: any) {
    const appStore = useAppStore();
    uploadObject.uploadProgress = 0;
    uploadObject.uploadId = uploadId;
    uploadId += 1;
    uploadObject.uploadCategory = 'flipCard';
    if (uploadObject.platform === 'mobile' && !uploadObject.file) {
      uploadObject.file = await this.pickFile();
      if (!uploadObject.file) {
        return;
      }
    }
    appStore.queue.push(uploadObject);
    if (appStore.queue.length === 1) {
      return await this.callUploadFunctionAndPostCard(appStore.queue[0]);
    }
  },
  uploadAudioBlob: async function (url: string) {
    const blob = useMatchStore().recordedBlob;
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + useAuthStore().accessToken;
    axios.defaults.headers.post['Content-Type'] = 'audio/wave';

    const config = {
      onUploadProgress: (progressEvent: { loaded: any }) => {
        useAppStore().queue.find((x: any) => x.uploadId === uploadId).uploadProgress =
          Math.round((progressEvent.loaded / blob.size) * 100) / 100;
      },
    };

    await axios
      .post(url, blob, config)
      .then((response) => {
        this.uploadFileSuccess(response.data);
        emitter.emit('clearAudioBlob');
      })
      .catch((error) => {
        useAppStore().postFailed(
          error,
          'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
          'Das Hochladen der Datei ist <b>fehlgeschlagen</b>',
        );
        this.uploadFinished();
      });
  },
  prepareUploadAudioBlob: async function (url: string) {
    // TODO: Diese Funktion und uploadAudioBlob mit uploadFile verbinden
    const uploadObject = {
      uploadProgress: 0,
      uploadId: uploadId,
      uploadCategory: 'mediaInbox',
      file: false,
      video: false,
      url: url,
      uploadType: 'audioBlob',
    } as any;

    const appStore = useAppStore();
    appStore.queue.push(uploadObject);
    if (appStore.queue.length === 1) {
      await this.callUploadFunction(appStore.queue[0]);
    }
  },
  uploadFile: async function (e: any, url: string, _uploadType: string) {
    const appStore = useAppStore();
    let files = [];
    if (appStore.isNative) {
      files[0] = await this.pickFile();
      if (!files[0]) {
        return;
      }
    } else {
      if (e.target && e.target.files) {
        files = e.target.files;
      } else if (e.dataTransfer && e.dataTransfer.files) {
        files = e.dataTransfer.files;
      } else if (e instanceof File) {
        files = [e];
      }
    }
    for (const file of files) {
      let fileType = '';
      if (file.type) {
        fileType = file.type;
      } else if (file.mimeType) {
        fileType = file.mimeType;
      }
      let uploadType = '';
      if (fileType.includes('audio')) {
        uploadType = 'audio';
      } else if (fileType.includes('image')) {
        uploadType = 'image';
      } else if (fileType.includes('video')) {
        uploadType = 'video';
      }
      const uploadObject = {
        uploadProgress: 0,
        uploadId: uploadId,
        uploadCategory: 'mediaInbox',
        file: file,
        video: false,
        url: url,
      } as any;
      uploadId += 1;
      if (uploadType === 'video') {
        uploadObject.video = true;
      }
      appStore.queue.push(uploadObject);
      if (appStore.queue.length === 1) {
        await this.callUploadFunction(appStore.queue[0]);
      }
    }
  },
  callUploadFunction(uploadObject: any) {
    if (uploadObject.uploadType === 'audioBlob') {
      this.uploadAudioBlob(uploadObject.url);
    } else {
      if (useAppStore().isNative) {
        if (uploadObject.video) {
          this.uploadVideoMobile(uploadObject.file, uploadObject.uploadId, uploadObject.url);
        } else {
          this.uploadFileMobile(uploadObject.file, uploadObject.uploadId, uploadObject.url);
        }
      } else {
        if (uploadObject.video) {
          this.uploadVideoDesktop(uploadObject.file, uploadObject.uploadId, uploadObject.url);
        } else {
          this.uploadFileDesktop(uploadObject.file, uploadObject.uploadId, uploadObject.url);
        }
      }
    }
  },
  async uploadFileMobile(files: any, uploadId: number, url: string) {
    const authStore = useAuthStore();
    const appStore = useAppStore();

    for (let x = 0; x < files.length; x++) {
      if (files[x].path !== null) {
        const audioFile = files[x].path;
        if (audioFile) {
          // - start transfer
          const options: FileUploadOptions = {
            httpMethod: 'POST',
            fileKey: 'file',
            fileName: files[x].name.replace(/ /g, '_'),
            headers: {
              Authorization: 'Bearer ' + authStore.accessToken,
            },
            mimeType: files[x].mimeType,
          };

          const fileTransfer: FileTransferObject = FileTransfer.create();
          fileTransfer.onProgress((progressEvent: any) => {
            appStore.queue.find((x: any) => x.uploadId === uploadId).uploadProgress =
              0.5 + progressEvent.loaded / progressEvent.total / 2;
          });
          fileTransfer
            .upload(audioFile, url, options)
            .then((data) => {
              // Fürs das Testen vom Hochladen von Dateien vor dem Deployment
              this.uploadFileSuccess(data.response);
            })
            .catch((error) => {
              appStore.postFailed(
                error,
                'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
                'Das Hochladen der Datei ist <b>fehlgeschlagen</b>',
              );
              this.uploadFinished();
            });
        }
      }
    }
  },
  uploadFileDesktop(file: any, uploadId: number, url: string) {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const formData = new FormData();
    formData.append('file', file);
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + authStore.accessToken;
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    const config = {
      onUploadProgress: (progressEvent: { loaded: any }) => {
        appStore.queue.find((x: any) => x.uploadId === uploadId).uploadProgress =
          Math.round((progressEvent.loaded / file.size) * 100) / 100;
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        // Fürs das Testen vom Hochladen von Dateien vor dem Deployment
        this.uploadFileSuccess(response.data);
      })
      .catch((error) => {
        appStore.postFailed(
          error,
          'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
          'Das Hochladen der Datei ist <b>fehlgeschlagen</b>',
        );
        this.uploadFinished();
      });
  },
  async uploadVideoMobile(files: any, uploadId: number, url: string) {
    const authStore = useAuthStore();
    const appStore = useAppStore();

    for (let x = 0; x < files.length; x++) {
      if (files[x].path !== null) {
        const videofile = files[x].path || '';

        VideoEditor.transcodeVideo(this.transcodeVideoOptions(videofile, files[x].name, uploadId))
          .then((fileUri: string) => {
            // - start transfer
            const options: FileUploadOptions = {
              httpMethod: 'POST',
              fileKey: 'file',
              fileName: 'name.mp4',
              headers: {
                Authorization: 'Bearer ' + authStore.accessToken,
              },
              mimeType: 'video/mp4',
            };

            const fileTransfer: FileTransferObject = FileTransfer.create();
            fileTransfer.onProgress((progressEvent: any) => {
              appStore.queue.find((x: any) => x.uploadId === uploadId).uploadProgress =
                0.5 + progressEvent.loaded / progressEvent.total / 2;
            });
            fileTransfer.upload(fileUri, url, options).then(
              (data) => {
                // Fürs das Testen vom Hochladen von Dateien vor dem Deployment
                this.uploadFileSuccess(data.response);
              },
              (err) => {
                appStore.postFailed(
                  err,
                  'Dir fehlen die Zugriffsrechte, um diese Karte zu posten.',
                  'Das Posten des Events ist <b>fehlgeschlagen</b>',
                );
                this.uploadFinished();
              },
            );
          })
          .catch((err: any) => {
            appStore.postFailed(
              err,
              'Dir fehlen die Zugriffsrechte, um diese Karte zu posten.',
              'Das Posten des Events ist <b>fehlgeschlagen</b>',
            );
            this.uploadFinished();
          });
      }
    }
  },
  async uploadVideoDesktop(file: any, uploadId: number, url: string) {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const formData = new FormData();
    formData.append('file', file);
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + authStore.accessToken;
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    const config = {
      onUploadProgress: (progressEvent: { loaded: any }) => {
        appStore.queue.find((x: any) => x.uploadId === uploadId).uploadProgress =
          Math.round((progressEvent.loaded / file.size) * 100) / 100;
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        // Fürs das Testen vom Hochladen von Dateien vor dem Deployment
        this.uploadFileSuccess(response.data);
      })
      .catch((err) => {
        appStore.postFailed(
          err,
          'Dir fehlen die Zugriffsrechte, um diese Karte zu posten.',
          'Das Posten des Events ist <b>fehlgeschlagen</b>',
        );
        this.uploadFinished();
      });
  },
  cancelUpload(i: number, platform: string) {
    const appStore = useAppStore();
    if (platform === 'desktop') {
      if (i !== 0) {
        appStore.queue.splice(i, 1);
      } else {
        controller.abort();
      }
    } else {
      if (i !== 0) {
        appStore.queue.splice(i, 1);
      } else {
        fileTransfer.abort();
        appStore.queue.shift();
      }
    }
  },
  callUploadFunctionAndPostCard: async function (e: any) {
    if (e.uploadType === 'video') {
      if (e.platform === 'desktop') {
        return this.uploadVideoDesktopAndPostCard(e.file, e.cardAssetUrl, e.uploadId, e.cardJSON);
      } else {
        return this.uploadVideoMobileAndPostCard(e.file, e.cardAssetUrl, e.uploadId, e.cardJSON);
      }
    } else {
      if (e.platform === 'desktop') {
        return this.uploadFileDesktopAndPostCard(e.file, e.cardAssetUrl, e.uploadType, e.uploadId, e.cardJSON);
      } else {
        return this.uploadFileMobileAndPostCard(e.file, e.cardAssetUrl, e.uploadType, e.uploadId, e.cardJSON);
      }
    }
  },
  uploadFileMobileAndPostCard: async function (
    files: any,
    cardAssetUrl: string,
    uploadType: string,
    uploadId: number,
    cardJSON: any,
  ) {
    const authStore = useAuthStore();
    const appStore = useAppStore();

    for (let x = 0; x < files.length; x++) {
      let filePath = '';
      if (files[x].path) {
        filePath = files[x].path;
      } else if (files[x].url) {
        filePath = files[x].url;
      }
      if (files[x].path || files[x].url) {
        const audioFile = filePath;
        if (audioFile) {
          // - start transfer
          const options: FileUploadOptions = {
            httpMethod: 'POST',
            fileKey: 'file',
            fileName: files[x].name.replace(/ /g, '_'),
            headers: {
              Authorization: 'Bearer ' + authStore.accessToken,
            },
            mimeType: files[x].mimeType,
          };

          fileTransfer = FileTransfer.create();
          fileTransfer.onProgress((progressEvent: any) => {
            appStore.queue.find((x: any) => x.uploadId === uploadId).uploadProgress =
              0.5 + progressEvent.loaded / progressEvent.total / 2;
          });
          fileTransfer
            .upload(audioFile, cardAssetUrl, options)
            .then(
              (data) => {
                // Fürs das Testen vom Hochladen von Dateien vor dem Deployment
                if (appStore.environment === 'dev') {
                  console.log(data.response);
                }
                if (uploadType === 'audio') {
                  cardJSON.elements[0].audioUrl = data.response;
                } else if (uploadType === 'image') {
                  cardJSON.elements[0].imageUrl = data.response;
                }
                if (appStore.queue.find((x: any) => x.uploadId === uploadId).postCard) {
                  this.postCard(cardJSON, uploadType);
                  return;
                } else {
                  this.uploadFinished(uploadType);
                  return cardJSON;
                }
              },
              (error) => {
                this.uploadFinished(uploadType);
                appStore.postFailed(
                  error,
                  'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
                  'Das Hochladen der Datei ist <b>fehlgeschlagen</b>',
                );
              },
            )
            .catch((error: any) => {
              this.uploadFinished(uploadType);
              appStore.postFailed(
                error,
                'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
                'Das Hochladen der Datei ist <b>fehlgeschlagen</b>',
              );
            });
        }
      }
    }
  },
  uploadFileDesktopAndPostCard: async function (
    file: any,
    cardAssetUrl: string,
    uploadType: string,
    uploadId: number,
    cardJSON: any,
  ) {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const formData = new FormData();
    formData.append('file', file);
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + authStore.accessToken;
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    const config = {
      onUploadProgress: (progressEvent: { loaded: any }) => {
        appStore.queue.find((x: any) => x.uploadId === uploadId).uploadProgress =
          Math.round((progressEvent.loaded / file.size) * 100) / 100;
      },
      signal: controller.signal,
    };

    return axios
      .post(cardAssetUrl, formData, config)
      .then(async (response: any) => {
        // Fürs das Testen vom Hochladen von Dateien vor dem Deployment
        if (appStore.environment === 'dev') {
          console.log(response.data);
        }
        if (uploadType === 'audio') {
          cardJSON.elements[0].audioUrl = response.data;
        } else if (uploadType === 'image') {
          cardJSON.elements[0].imageUrl = response.data;
        }
        if (appStore.queue.find((x: any) => x.uploadId === uploadId).postCard) {
          // noinspection ES6MissingAwait
          this.postCard(cardJSON, uploadType);
          return;
        } else {
          this.uploadFinished(uploadType);
          return cardJSON;
        }
      })
      .catch((error: any) => {
        this.uploadFinished(uploadType);
        appStore.postFailed(
          error,
          'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
          'Das Hochladen der Datei ist <b>fehlgeschlagen</b>',
        );
        return;
      });
  },
  uploadVideoMobileAndPostCard: async function (files: any, cardAssetUrl: string, uploadId: number, cardJSON: any) {
    const authStore = useAuthStore();
    const appStore = useAppStore();

    for (let x = 0; x < files.length; x++) {
      let filePath = '';
      if (files[x].path) {
        filePath = files[x].path;
      } else if (files[x].url) {
        filePath = files[x].url;
      }
      if (files[x].path || files[x].url) {
        VideoEditor.transcodeVideo(this.transcodeVideoOptions(filePath, files[x].name, uploadId))
          .then((fileUri: string) => {
            // - start transfer
            const options: FileUploadOptions = {
              httpMethod: 'POST',
              fileKey: 'file',
              fileName: 'name.mp4',
              headers: {
                Authorization: 'Bearer ' + authStore.accessToken,
              },
              mimeType: 'video/mp4',
            };

            fileTransfer = FileTransfer.create();
            fileTransfer.onProgress((progressEvent: any) => {
              appStore.queue.find((x: any) => x.uploadId === uploadId).uploadProgress =
                0.5 + progressEvent.loaded / progressEvent.total / 2;
            });
            fileTransfer.upload(fileUri, cardAssetUrl, options).then(
              (data) => {
                // Fürs das Testen vom Hochladen von Dateien vor dem Deployment
                if (appStore.environment === 'dev') {
                  console.log(data.response);
                }
                cardJSON.elements[0].videoUrl = data.response;
                if (appStore.queue.find((x: any) => x.uploadId === uploadId).postCard) {
                  this.postCard(cardJSON, 'video');
                  return;
                } else {
                  this.uploadFinished('video');
                  return cardJSON;
                }
              },
              (error) => {
                this.uploadFinished('video');
                appStore.postFailed(
                  error,
                  'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
                  'Das Hochladen der Datei ist <b>fehlgeschlagen</b>',
                );
              },
            );
          })
          .catch((error: any) => {
            this.uploadFinished('video');
            appStore.postFailed(
              error,
              'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
              'Das Hochladen der Datei ist <b>fehlgeschlagen</b>',
            );
          });
      }
    }
  },
  uploadVideoDesktopAndPostCard: async function (file: any, cardAssetUrl: string, uploadId: number, cardJSON: any) {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const formData = new FormData();
    formData.append('file', file);
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + authStore.accessToken;
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    const config = {
      onUploadProgress: (progressEvent: { loaded: any }) => {
        appStore.queue.find((x: any) => x.uploadId === uploadId).uploadProgress =
          Math.round((progressEvent.loaded / file.size) * 100) / 100;
      },
      signal: controller.signal,
    };

    axios
      .post(cardAssetUrl, formData, config)
      .then((response: any) => {
        // Fürs das Testen vom Hochladen von Dateien vor dem Deployment
        if (appStore.environment === 'dev') {
          console.log(response.data);
        }
        cardJSON.elements[0].videoUrl = response.data;
        if (appStore.queue.find((x: any) => x.uploadId === uploadId).postCard) {
          this.postCard(cardJSON, 'video');
          return;
        } else {
          this.uploadFinished('video');
          return cardJSON;
        }
      })
      .catch((error: any) => {
        this.uploadFinished('video');
        appStore.postFailed(
          error,
          'Dir fehlen die Zugriffsrechte, um eine Datei hochzuladen.',
          'Das Hochladen der Datei ist <b>fehlgeschlagen</b>',
        );
      });
  },
  uploadFinished(uploadType?: string) {
    const appStore = useAppStore();
    if (uploadType && appStore.queue[0].postCard) {
      emitter.emit('callResetCard', uploadType);
    }
    appStore.queue.shift();
    if (appStore.queue.length) {
      if (appStore.queue[0].uploadCategory === 'flipCard') {
        this.callUploadFunctionAndPostCard(appStore.queue[0]);
      } else if (appStore.queue[0].uploadCategory === 'mediaInbox') {
        this.callUploadFunction(appStore.queue[0]);
      }
    }
  },
  postCard: async function (cardJSON: any, uploadType: string) {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const matchStore = useMatchStore();
    let failed = false;

    emitter.emit('callResetCard', uploadType);

    axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
    axios.defaults.headers.post['Authorization'] = 'Bearer ' + authStore.accessToken;

    if (matchStore.recordedBlob) {
      const blob = matchStore.recordedBlob;
      axios.defaults.headers.post['Authorization'] = 'Bearer ' + authStore.accessToken;
      axios.defaults.headers.post['Content-Type'] = 'audio/wave';

      await axios
        .post(
          'https://cloud-' +
            appStore.environment +
            '.leagues.football/api/media/blobUploadCardAsset?cardId=' +
            cardJSON.id +
            '&filename=name.m4a',
          blob,
        )
        .then((response) => {
          cardJSON.elements[0].audioUrl = response.data;
          emitter.emit('clearAudioBlob');
        })
        .catch((err) => {
          this.uploadFinished(uploadType);
          appStore.postFailed(
            err,
            'Dir fehlen die Zugriffsrechte, um diese Karte zu posten.',
            'Das Posten des Events ist <b>fehlgeschlagen</b>',
          );
          failed = true;
          return;
        });
    }
    if (failed) {
      return;
    }

    await axios
      .request({
        method: 'post',
        data: cardJSON,
        url: 'https://cloud-' + appStore.environment + '.leagues.football/api/timelines/addCard',
      })
      .catch((err) => {
        this.uploadFinished(uploadType);
        appStore.postFailed(
          err,
          'Dir fehlen die Zugriffsrechte, um diese Karte zu posten.',
          'Das Posten des Events ist <b>fehlgeschlagen</b>',
        );
        failed = true;
        return;
      });
    if (failed) {
      return;
    }

    const requestURL =
      'https://cloud-' +
      appStore.environment +
      '.leagues.football/api/timelines/placeCard?cardId=' +
      cardJSON.id +
      '&timelineId=' +
      authStore.userSettings.selectedTimeline.uid;
    await axios
      .post(requestURL)
      .then(() => {
        this.uploadFinished(uploadType);
        const bannerObject = {
          success: true,
          message: 'Dein Liveticker Event wurde <b>veröffentlicht</b>',
        };
        if (appStore.environment === 'dev') {
          console.log(cardJSON);
        }
        appStore.addBanner(bannerObject);
      })
      .catch((err) => {
        this.uploadFinished(uploadType);
        appStore.postFailed(
          err,
          'Dir fehlen die Zugriffsrechte, um diese Karte zu posten.',
          'Das Posten des Events ist <b>fehlgeschlagen</b>',
        );
      });
  },
  postAfterUpload(cardId: string) {
    const appStore = useAppStore();
    if (appStore.queue.find((x: any) => x.cardJSON.id === cardId)) {
      appStore.queue.find((x: any) => x.cardJSON.id === cardId).postCard = true;
      return true;
    } else {
      return;
    }
  },
  async pickFile() {
    const result = await FilePicker.pickMedia({
      limit: 1,
    }).catch(() => {
      return;
    });
    return result?.files;
  },
  uploadFileSuccess(data: any) {
    const appStore = useAppStore();
    if (appStore.environment === 'dev') {
      console.log(data);
    }
    const bannerObject = {
      success: true,
      message: 'Die Datei wurde erfolgreich <b>hochgeladen</b>',
    };
    appStore.addBanner(bannerObject);
    emitter.emit('refreshPage');
    this.uploadFinished();
  },
};
