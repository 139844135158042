<template>
  <ion-item>
    <div class="flex items-center cursor-pointer" @click="toggleAudio">
      <ion-icon class="play" :icon="pause" v-if="playing"></ion-icon>
      <ion-icon class="pause" :icon="play" v-else></ion-icon>
      <ion-label class="cursor-pointer">{{ getFileName(audio.url) }}</ion-label>
    </div>
    <div class="relative hidden">
      <audio ref="audioRef" class="audio-preview" controls controlsList="nodownload noplaybackrate">
        <source :src="audio.url" type="audio/ogg" />
        <source :src="audio.url" type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    </div>
    <ion-icon v-if="audio.protected" slot="end" :icon="lockClosed" color="warning"></ion-icon>
  </ion-item>

  <ion-item-options v-if="!(!mediaAutoAudioProtectedPermission && audio.protected)" side="end">
    <ion-item-option color="danger" @click="requestDelete(audio.key, audio.protected)">
      <ion-icon slot="icon-only" :icon="trash"></ion-icon>
    </ion-item-option>
  </ion-item-options>
</template>

<script lang="ts" setup>
import { computed, onActivated, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { lockClosed, pause, play, trash } from 'ionicons/icons';
import { alertController, IonIcon, IonItem, IonItemOption, IonItemOptions, IonLabel } from '@ionic/vue';
import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import axios from 'axios';
import { emitter } from '@/bus';

const showAudio = ref(false);
const playing = ref(false);
const audioRef = ref<HTMLAudioElement>();

const props = defineProps<{
  audio: { key: any; value: any; url: string; protected: boolean };
  mediaAutoAudioProtectedPermission?: boolean;
  audioActive?: boolean;
}>();

const getFileName = computed(() => (url: string) => url.split('/').pop());

function deleteAudio(key: string) {
  const activeClubUid = useAuthStore().activeClubUid;
  if (!activeClubUid) {
    return;
  }

  const appStore = useAppStore();
  const requestURL =
    'https://cloud-' +
    appStore.environment +
    '.leagues.football/api/autoaudio/delete?entityId=' +
    activeClubUid +
    '&key=' +
    key;
  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: 'Bearer ' + useAuthStore().accessToken,
  };
  axios
    .delete(requestURL, { headers })
    .then(() => {
      emitter.emit('refreshPage');
    })
    .catch(() => {
      const bannerObject = {
        success: false,
        message: 'Das Löschen der Datei ist fehlgeschlagen',
      };
      appStore.addBanner(bannerObject);
    });
}
async function requestDelete(key: string, protectedAudio: boolean) {
  let header = '';
  let subheader = '';
  if (protectedAudio) {
    header = 'Achtung!';
    subheader = 'Das ist eine geschützte Datei. Bist du dir sicher, dass du diese Datei löschen willst?';
  } else {
    subheader = 'Bist du dir sicher, dass du diese Datei löschen willst?';
  }
  const alert = await alertController.create({
    header: header,
    cssClass: 'custom-alert',
    subHeader: subheader,
    buttons: [
      {
        text: 'Ja',
        role: 'confirm',
        handler: () => {
          deleteAudio(key);
        },
      },
      {
        text: 'abbrechen',
        role: 'cancel',
      },
    ],
  });

  await alert.present();
  return;
}
function setShowAudioFalse() {
  if (props.audio && props.audio.key) {
    emitter.emit('setShowAudioFalse', props.audio.key);
  }
}
function toggleAudio() {
  if (!audioRef.value) {
    return;
  }
  if (playing.value) {
    playing.value = false;
    audioRef.value.pause();
    audioRef.value.currentTime = 0;
  } else {
    playing.value = true;
    audioRef.value.play();
  }
}

watch(showAudio, (newVal) => {
  if (newVal) {
    setShowAudioFalse();
  }
});

onActivated(() =>
  emitter.on('setShowAudioFalse', (e) => {
    if (props.audio && props.audio.key !== e) {
      showAudio.value = false;
    }
  }),
);

onMounted(() => audioRef?.value?.addEventListener('ended', () => toggleAudio()));

onBeforeUnmount(() => emitter.all.delete('setShowAudioFalse'));
</script>

<style lang="scss" scoped>
.audio-preview {
  height: 47px;
  max-width: 100%;
}

.stop-audio {
  width: 55px;
  height: 55px;
  position: absolute;
  top: -4px;
  right: -2px;
}

.play,
.pause {
  padding-right: 10px;
  width: 24px;
  height: 24px;
}
</style>
